import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"



const Index = () => (
  <>
    <DownloadFilePage
      downloadURL={`${process.env.resourceUrl}docs/manual/XREAL_Air2Pro_Quick_Start_Guide.pdf`}
    />
  </>
)

export default Index